import { 
    createContext,
    useContext,
    useState } from "react";

const UserConfigsContext = createContext({
    currenteEmpre:null,
    setCurrentEmpre: ()=>{},
    currentUnineg:null,
    setCurrentUnineg: ()=>{},
});

export const useUserConfig =()=> useContext(UserConfigsContext);

const UserConfigsProvider = ({children}) =>{
    const [currenteEmpre, setCurrentEmpre] = useState(null);
    const [currentUnineg, setCurrentUnineg] = useState(null);
    return(
        <UserConfigsContext.Provider value={{
            currenteEmpre,
            setCurrentEmpre,
            currentUnineg,
            setCurrentUnineg
        }}>
            {children}
        </UserConfigsContext.Provider>
    )
};
export default UserConfigsProvider;
