import { createContext, useContext, useEffect, useState } from 'react';
//Llamada a la API
import { IsLogged } from '../api/authAPI';

const AuthContext = createContext({
  auth: null,
  setAuth: () => {},
  codempre: null,
  setCodempre: () => {},
  uniNegs: [],
  setUninegs: () => {},
  usuario: '',
  setUsuario: () => {},
});

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const [codempre, setCodempre] = useState(null);
  const [uniNegs, setUninegs] = useState([]);
  const [usuario, setUsuario] = useState('');
  useEffect(() => {
    const isAuth = async () => {
      try {
        const response = await IsLogged();//Hacer llamada a la API
        if (response.data.loggedIn === true) {
          const {usuario,codempre, uniNegs, loggedIn} = response.data;
          setAuth(loggedIn); 
          setCodempre(codempre);
          setUninegs(uniNegs);
          setUsuario(usuario)
        }else{
          setAuth(null);
        }
      } catch(error) {
        setAuth(null);
      };
    };
    isAuth();
  }, [auth]);
  return (
    <AuthContext.Provider value={{ auth, setAuth, codempre,setCodempre, uniNegs, setUninegs, usuario, setUsuario }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;