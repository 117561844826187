import React from 'react';
import Form from 'react-bootstrap/Form';

export default function SelectCliente({
    nombre, value, handleChange, handleBlur, empresas,
    loadingEmpresas, errorEmpresas, className,codorden}) {

    if (loadingEmpresas) return <h4>CARGANDO...</h4>
    if (errorEmpresas) return <h4>Algo salio mal</h4>
    
    return (
        <>
            <Form.Select aria-label="Cliente" name={nombre} value={value} 
                disabled={codorden === undefined ? false : true}
                onChange={handleChange} onBlur={handleBlur} className={className}>
                {empresas ? empresas.map((empresa) => {
                    return (
                        <option value={empresa.codempre} key={`empre${empresa.codempre}`}>
                            {empresa.nomempre}
                        </option>
                    )
                }) : null}
            </Form.Select>
        </>
    )
}
