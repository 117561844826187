import React, {useState} from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useFormik } from 'formik';
import { coordenadasSchema } from '../../schemas/coordenadasSchema';
import { compararCoordenadas } from '../../api/authAPI';
import { useAuth } from '../../Contexts/AuthContext';

export default function Coordenadas({ coordenadas, tarjeta }) {
    const { setAuth, setCodempre, setUninegs, setUsuario } = useAuth();
    const [error,setError] = useState(false);
    const [errorText, setErrorText] = useState(null);
    const onSubmit = async (values, actions) => {
        try {
            const merged = {
                'solicitadas': { ...coordenadas },
                'ingresadas': { ...values },
                tarjeta
            }
            const response = await compararCoordenadas(merged);
            //El estado 200 me dice indica si se logeo correctamente
            if (response.status === 200) {
                //Guardo la autenticacion y cargo datos de utilidad
                //en el contexto de la app
                const { codempre, uniNegs, usuario } = response.data;
                setAuth(true);
                setCodempre(codempre);
                setUninegs(uniNegs);
                setUsuario(usuario);
            }
        } catch (error) {
            if (error.response) {
                setError(true)
                setErrorText(error.response.data.msj)
            }
        }

        actions.resetForm();
    };
    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
    } = useFormik({
        initialValues: {
            coordenada1: "",
            coordenada2: "",
        },
        validationSchema: coordenadasSchema,
        onSubmit,
    });
    return (
        <>
            {error ?
                <div className='alert alert-danger' role="alert" style={{ textAlign: "center" }}>
                    <b>
                        {errorText}
                    </b>
                </div>
                :
                null
            }
            <Container className='coordenadas justify-content-md-center'>
                <Form onSubmit={handleSubmit}>
                    <Row xs="auto" className="justify-content-md-center">
                        <Col md="6">
                            <h5>INGRESAR COORDENADAS</h5>
                        </Col>
                    </Row>
                    <hr />
                    <Row xs={2} className="justify-content-md-center">
                        <Form.Group
                            as={Col}
                            md="6"
                        >
                            <Form.Label className='text-center'>{`${coordenadas[0].letra}${coordenadas[0].posicion}`}</Form.Label>
                            <Form.Control
                                type="number"
                                name="coordenada1"
                                value={values.coordenada1}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.coordenada1 && touched.coordenada1 ? "input-error" : ""}
                            />
                            {errors.coordenada1 && touched.coordenada1 && (
                                <p className="error">{errors.coordenada1}</p>
                            )}
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            md="6"
                        >
                            <Form.Label className='text-center'>{`${coordenadas[1].letra}${coordenadas[1].posicion}`}</Form.Label>
                            <Form.Control
                                type="number"
                                name="coordenada2"
                                value={values.coordenada2}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={errors.coordenada2 && touched.coordenada2 ? "input-error" : ""}
                            />
                            {errors.coordenada2 && touched.coordenada2 && (
                                <p className="error">{errors.coordenada2}</p>
                            )}
                        </Form.Group>
                    </Row>
                    <hr />
                    <Row>
                        <Button className='customBtn' disabled={isSubmitting} type="submit">Ingresar</Button>
                    </Row>
                    <hr />
                </Form>
            </Container>
        </>
    )
}
