import React, { useEffect } from 'react'
import { useFetchCuposSinAsig } from '../CustomHooks/useCuposSinAsig';

export default function TablaCuposSinAsigModal({ codempre, unineg,asigCamionCupo }) {
    const { cupos, loadingCupos, errorCupos, loadCuposSinAsig } = useFetchCuposSinAsig();
    const tableStyle = {
    }
    useEffect(() => {
        loadCuposSinAsig(unineg, codempre)
    }, [])

    const handleAsignar = (e,ctrl,cantidad)=>{
        const confirma = window.confirm("¿Confirma que quiere vincular el cupo al camion?");
        if(confirma){
            asigCamionCupo(ctrl,cantidad)
        }
    };

    if (loadingCupos) return <h4>CARGANDO...</h4>
    if (errorCupos) return <h4>Algo salio mal</h4>

    return (
        <>
            <table className="table table-striped table-light table-sm table-hover" style={tableStyle}>
                <thead className='table-dark'>
                    <tr>
                        <th scope="col">Fecha Gen.</th>
                        <th scope="col">Despacho</th>
                        <th scope="col">Envase</th>
                        <th scope="col">Mezcla</th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">Obs</th>
                        <th scope="col">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {cupos ? cupos.map((cupo) => {
                        return (
                            <tr key={cupo.ctrl}>
                                <td data-titulo='Tipo despacho'>{String(cupo.fechagen).trimEnd().substring(0, 19).replace('T', ' ')}</td>
                                <td data-titulo='Tipo envase'>{cupo.nomdespacho}</td>
                                <td data-titulo='Carga máxima'>{cupo.nombreenvase}</td>
                                <td data-titulo='Fecha gen'>{cupo.mezcla}</td>
                                <td data-titulo='Mezcla'>{cupo.cantidad}</td>
                                <td data-titulo='Obs'>
                                    {cupo.obs.length > 10 ? String(cupo.obs).substring(0, 20) + '...' : cupo.obs}</td>
                                <td data-titulo='Acción'>
                                    <a onClick={(e)=>handleAsignar(e,cupo.ctrl,cupo.cantidad)} style={{cursor:"pointer"}}>Asginar</a>
                                </td>
                            </tr>
                        )
                    })
                        : null}
                </tbody>
            </table></>
    )
}
