import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/esm/Container';
import { ToastContainer, toast } from 'react-toastify';
import { useEmpresas } from '../components/CustomHooks/useEmpresas';
import { useAuth } from '../Contexts/AuthContext';
import {useUserConfig} from '../Contexts/UserConfigsProvider';
import ModalCargando from '../components/ModalCargando';
import SelectCliente from '../components/Cupos/SelectCliente';
import SelectUniNeg from '../components/Cupos/SelectUniNeg';
import SelectEstadosCamion from '../components/Camiones/SelectEstadosCamion';
import TablaCamiones from '../components/Camiones/TablaCamiones';
import ModalCupoSinAsig from '../components/Camiones/ModalCupoSinAsig';
import ModalCuposAsig from '../components/Camiones/ModalCuposAsig';
import { asignarCamionCupo, apiDesAsigCamionCupo } from '../api/camionesAPI';
import ModalTicketsCamion from '../components/Camiones/ModalTicketsCamion';
export default function Camiones() {
  const { empresas, loadingEmpresas, errorEmpresas } = useEmpresas();
  const { uniNegs } = useAuth();
  const {
    currenteEmpre,
    setCurrentEmpre,
    currentUnineg,
    setCurrentUnineg
  } = useUserConfig();
  const [filtrar, setFiltrar] = useState(false);
  const [camionSeleccionado, setCamionSeleccionado] = useState(null);
  const [esperandoRtaBackend, setEsperandoRtaBackEnd] = useState(false);
  const [errorRespuesta, setErrorRespuesta] = useState([]);
  const [errorAltaAct, setErrorAltaAct] = useState(false);
  const [busqueda, setBusqueda] = useState('');
  const [filtros, setFiltros] = useState({
    codempre: undefined,
    unineg: undefined,
    estado: 0,
  })
  const [showModalCuposSinAsig, setShowModalCuposSinAsig] = useState(false);
  const [showModalCuposAsig, setShowModalCuposAsig] = useState(false);
  const [showModalTicketCamion, setShowModalTicketCamion] = useState(false);
  const [opcDesasig, setOpcDesasig] = useState(null);
  const valoresPorDefecto = async () => {
    if (empresas !== null && uniNegs !== null) {
      setFiltros({
        ...filtros,
        unineg: currentUnineg ? currentUnineg:uniNegs[0].ctrl,
        codempre: currenteEmpre ? currenteEmpre: empresas[0].codempre,
      })
      handleFiltrar()
    }
  };

  const handleChange = (e) => {
    if(e.target.name === 'codempre'){
      setCurrentEmpre(e.target.value);
    }else if (e.target.name === 'unineg'){
      setCurrentUnineg(e.target.value);
    }    
    setFiltros({
      ...filtros,
      [e.target.name]: e.target.value
    })
  };
  const handleFiltrar = async () => {
    const aux = !filtrar;
    setFiltrar(aux);
  };
  const handleBuscar = (e) => {
    setBusqueda(e.target.value)
  };

  const asigCamionCupo = async (ctrl, cantidad) => {
    try {
      setErrorRespuesta([])
      setEsperandoRtaBackEnd(true);
      const response = await asignarCamionCupo(
        ctrl,
        camionSeleccionado,
        cantidad
      )
      if (response.status === 201 && response.data.actualizado === 1) {
        toast.success('Datos guardados con exito!!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setErrorAltaAct(false);
      } else {
        setErrorAltaAct(true);
        setErrorRespuesta([{
          error: 'Algo salió mal'
        }])
      }
    } catch (error) {
      setErrorAltaAct(true);
      setErrorRespuesta([{
        error: 'Algo salió mal'
      }])
    } finally {
      const aux = !filtrar;
      setFiltrar(aux);
      setCamionSeleccionado(null);
      setShowModalCuposSinAsig(false)
      setEsperandoRtaBackEnd(false);
      window.scrollTo(0, 0);
    }
  };

  const desAsigCamionCupo = async (ctrl) => {
    try {
      setErrorRespuesta([])
      setEsperandoRtaBackEnd(true);
      const response = await apiDesAsigCamionCupo(
        ctrl,
        camionSeleccionado,
      )
      if (response.status === 201 && response.data.resultado[0].result === 1) {
        toast.success('Datos guardados con exito!!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setErrorAltaAct(false);
      } else {
        setErrorAltaAct(true);
        setErrorRespuesta([{
          error: 'Algo salió mal'
        }])
      }
    } catch (error) {
      setErrorAltaAct(true);
      setErrorRespuesta([{
        error: 'Algo salió mal'
      }])
    } finally {
      const aux = !filtrar;
      setFiltrar(aux);
      setCamionSeleccionado(null);
      setShowModalCuposAsig(false)
      setEsperandoRtaBackEnd(false);
      window.scrollTo(0, 0);
    }
  };
  useEffect(() => {
    valoresPorDefecto()
    setErrorAltaAct(false)
    setErrorRespuesta([])
  }, [empresas, uniNegs])
  return (
    <Container fluid>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        theme="colored"
      />
      {errorAltaAct ? <Alert key='danger' variant='danger' autoFocus>
        Los siguientes errores impidieron guardar los datos:
        <ul>
          {errorRespuesta.map((err, index) => {
            return (<li key={`errRtaServer${index}`}>{err.error}</li>)
          })}
        </ul>
      </Alert> : null}
      <Form>
        <Row className="mb-3">
          <Col sm={true}>
            <Form.Label>Cliente</Form.Label>
            <SelectCliente handleChange={handleChange}
              nombre={'codempre'} value={filtros.codempre} empresas={empresas}
              loadingEmpresas={loadingEmpresas} errorEmpresas={errorEmpresas}
            />
          </Col>
          <Col sm={true}>
            <Form.Label>Unidad de Negocio</Form.Label>
            <SelectUniNeg uniNegs={uniNegs}
              handleChange={handleChange}
              nombre={'unineg'} value={filtros.unineg}
            />
          </Col>
          <Col sm={true}>
            <Form.Label>Estado</Form.Label>
            <SelectEstadosCamion handleChange={handleChange}
              nombre={'estado'} value={filtros.estado}
            />
          </Col>
          <Col sm={true}>
            <Form.Label>Buscar</Form.Label>
            <Form.Control type="text" placeholder="Busqueda" onChange={handleBuscar} />
          </Col>
          <Col sm={true}>
            <Form.Label>Aplicar filtros</Form.Label>
            <Button variant='success' onClick={handleFiltrar}>
              Filtrar
            </Button>
          </Col>
        </Row>
      </Form>
      <TablaCamiones
        filtros={filtros}
        filtrar={filtrar}
        busqueda={busqueda}
        setShowModalCuposSinAsig={setShowModalCuposSinAsig}
        setShowModalCuposAsig={setShowModalCuposAsig}
        setShowModalTicketCamion={setShowModalTicketCamion}
        setCamionSeleccionado={setCamionSeleccionado}
        setOpcDesasig={setOpcDesasig} />
      {showModalCuposSinAsig ? /*Para que no se hagan fetchs */
        <ModalCupoSinAsig
          showModalCuposSinAsig={showModalCuposSinAsig}
          setShowModalCuposSinAsig={setShowModalCuposSinAsig}
          setCamionSeleccionado={setCamionSeleccionado}
          codempre={filtros.codempre}
          unineg={filtros.unineg}
          asigCamionCupo={asigCamionCupo}
        />
        : null}
      {showModalCuposAsig ?
        <ModalCuposAsig
          showModalCuposAsig={showModalCuposAsig}
          setShowModalCuposAsig={setShowModalCuposAsig}
          setCamionSeleccionado={setCamionSeleccionado}
          camionSeleccionado={camionSeleccionado}
          setOpcDesasig={setOpcDesasig}
          opcDesasig={opcDesasig}
          desAsigCamionCupo={desAsigCamionCupo}
        />
        : null}
        {showModalTicketCamion ?
          <ModalTicketsCamion
            showModalTicketCamion={showModalTicketCamion}
            setShowModalTicketCamion={setShowModalTicketCamion}
            camionSeleccionado={camionSeleccionado}  
            setCamionSeleccionado={setCamionSeleccionado}          
          />
        :null}
      <ModalCargando esperandoRtaBackend={esperandoRtaBackend} />
    </Container>
  )
}
