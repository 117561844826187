import React,{useEffect} from 'react' 

export default function ({loading,error,stock}) {
    useEffect(()=>{

    },[loading,error,stock])
    if (loading) return <h4>CARGANDO...</h4>
    if (error) return <h4>Algo salio mal</h4>
    return (
        <>
            <table className="table table-striped table-light table-hover table-sm ms-auto me-auto"
            style={{width:'90%'}}>
                <thead className='table-dark'>
                    <tr>
                        <th scope="col">Código</th>
                        <th scope="col">Producto</th>
                        <th scope="col">Toneladas</th>
                    </tr>
                </thead>
                <tbody>
                    {!loading && !error ? stock.map((s,index) => {
                        return (
                            <tr key={`stock${index}`}>
                                <td data-titulo='Código'>{s.codpro}</td>
                                <td data-titulo='Producto'>{s.nompro}</td>
                                <td data-titulo='Toneladas'>{s.toneladas}</td>
                            </tr>
                        )
                    }) : null}
                </tbody>
            </table>
        </>
    )
}
