import axios from "axios";
import {BASE_URL} from '../config';

axios.defaults.withCredentials = true;
// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    if(error.response.status === 401) window.location.href = '/';
    return Promise.reject(error);
  });
/*Peticion al endpoint del backend para obtener los productos del
cliente en base el id de empresa que este seleccionado */
export const getProdClientes = async (cliente,unineg) => {
    return await axios.post(`${BASE_URL}/api/productos/productos-clientes`, {cliente,unineg});
};