import { useEffect, useState } from 'react';
import { getCamionesEnPlayaEmpre } from '../../api/camionesAPI';
import BodyTablaCamiones from './BodyTablaCamiones';

export default function TablaCuposSinAig({ filtros, filtrar, busqueda,
    setShowModalCuposSinAsig,setCamionSeleccionado,setShowModalCuposAsig,
    setOpcDesasig,setShowModalTicketCamion
}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [camiones, setCamiones] = useState([]);
    const [camionesFiltrados, setCamionesFiltrados] = useState([]);
    const loadCamiones = async () => {
        try {
            const response = await getCamionesEnPlayaEmpre(filtros);
            setCamiones(response.data)
        } catch (error) {
            setError(true)
        } finally {
            setLoading(false)
        }
    };
    const filtrarCamiones = (str) => {
        if (busqueda === '' || busqueda === null || busqueda === undefined) {
            //En este caso retono todos los camiones porque no tengo criterio para filtrar
            setCamionesFiltrados(camiones)
        }
        if (camiones.length !== 0) {
            const aux = camiones.slice(0)
            const camiones_filtrados = aux.filter(o =>
                Object.keys(o).some(k => String(o[k]).toLowerCase().includes(str.toLowerCase())));
            setCamionesFiltrados(camiones_filtrados);
        } else {
            setCamionesFiltrados([])
        }
    };
    useEffect(() => {
        if (filtros.codempre !== undefined && filtros.unineg !== undefined) {
            setLoading(true)
            loadCamiones()
        }
    }, [filtrar]);

    useEffect(() => {
        filtrarCamiones(busqueda)
    }, [busqueda]);

    const tableStyle = {
        margin: "0 auto",
    }

    if (loading) return <h4>CARGANDO...</h4>
    if (error) return <h4>Algo salio mal</h4>
    if (camiones.length === 0) return <h4>No se encontraron camiones</h4>

    return (
        <div>
            <table className="table table-striped table-light table-sm table-hover" style={tableStyle}>
                <thead className='table-dark'>
                    <tr>
                        <td scope='col'>ID</td>
                        <td scope='col'>Cliente</td>
                        <td scope='col'>Patente chasis</td>
                        <td scope='col'>Patente acoplado</td>
                        <td scope='col'>Carga teórica</td>
                        <td scope='col'>Nombre chofer</td>
                        <td scope='col'>DNI</td>
                        <td scope='col'>Ingreso</td>
                        <td scope='col'>Estado</td>
                        <td scope='col'>Cupo</td>
                        <td scope='col'>Acción</td>
                    </tr>
                </thead>
                <tbody>
                    <BodyTablaCamiones 
                    arr={!busqueda?camiones:camionesFiltrados}
                    setShowModalCuposSinAsig={setShowModalCuposSinAsig}
                    setCamionSeleccionado={setCamionSeleccionado}
                    setShowModalCuposAsig={setShowModalCuposAsig}
                    setShowModalTicketCamion={setShowModalTicketCamion}
                    setOpcDesasig={setOpcDesasig}/>
                </tbody>
            </table>
        </div>
    )
}
