import * as Yup from 'yup';

export const genCupoSchema = Yup.object().shape({
    disableEnvases: Yup.boolean(),
    cliente: Yup.number("Cliente tiene que ser un numero").required("Este campo es obligatorio"),
    uniNeg: Yup.number("UniNeg tiene que ser un numero").required("Este campo es obligatorio"),
    tipoDespacho: Yup.number("Tip despacho tiene que ser un numero").required("Este campo es obligatorio"),
    envase: Yup.number().required("Este campo es obligatorio"),
    cargaMaxima: Yup.number("La carga maxima debe ser un numero")
        .required("Este campo es obligatorio")  
        .positive("La carga maxima debe ser un valor positivo"),
    cantCuposIguales: Yup.number("La cantidad de cupos debe ser un numero")
        .required("Este campo es obligatorio")  
        .positive("La cantidad cupos debe ser un valor positivo")
  });
