import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { useFetchCuposSinAsig } from '../CustomHooks/useCuposSinAsig'
import { deleteCupo } from '../../api/cuposAPI';
import ModalDetalleCupo from './ModalDetalleCupo';

export default function TablaCuposSinAig({ uniNeg, codEmpre }) {
    const navigate = useNavigate();
    const [eliminado, setEliminado] = useState(false);
    const [idCupoSeleccionado, setIdCupoSeleccionado] = useState(null);
    const [showModalDetalle, setShowModalDetalle] = useState(false);
    const { cupos, loadingCupos, errorCupos, loadCuposSinAsig } = useFetchCuposSinAsig();
    const handleCloseModal = () => setShowModalDetalle(false);
    const handleShowModal = (codorden) => {
        setShowModalDetalle(true);
        setIdCupoSeleccionado(codorden);
    };
    const handleModificar = (codorden) => {
        navigate(`/cupos/modificar/${codorden}`);
    };
    const handleEliminar = async (codorden) => {
        try {
            const confirma = window.confirm("¿Confirma que quiere eliminar el cupo?");
            if (confirma) {
                const rta = await deleteCupo(codorden);
                const aux = !eliminado
                setEliminado(aux)
            }
        } catch (error) {
            /*Mostrar msj de error */
            window.alert('Algo salió mal.')
        }
    };
    useEffect(() => {
        loadCuposSinAsig(uniNeg, codEmpre)
    }, [uniNeg, codEmpre, eliminado]);

    if (loadingCupos) return <h4>CARGANDO...</h4>
    if (errorCupos) return <h4>Algo salio mal</h4>
    if (cupos.length === 0) return <h4>No se encontraron cupos</h4>

    const tableStyle = {
        margin: "0 auto",
    }
    return (
        <div>
            <table className="table table-striped table-light table-hover" style={tableStyle}>
                <thead className='table-dark'>
                    <tr>
                        <th scope="col">Fecha Gen.</th>
                        <th scope="col">Despacho</th>
                        <th scope="col">Envase</th>
                        <th scope="col">Mezcla</th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">Obs</th>
                        <th scope="col">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {cupos ? cupos.map((cupo) => {
                        return (
                            <tr key={cupo.ctrl}>
                                <td data-titulo='Fecha Gen.'>{String(cupo.fechagen).trimEnd().substring(0, 19).replace('T', ' ')}</td>
                                <td data-titulo='Despacho'>{cupo.nomdespacho}</td>
                                <td data-titulo='Envase'>{cupo.nombreenvase}</td>
                                <td data-titulo='Mezcla'>{cupo.mezcla}</td>
                                <td data-titulo='Cantidad'>{cupo.cantidad}</td>
                                <td data-titulo='Obs'>
                                    {cupo.obs.length > 10 ? String(cupo.obs).substring(0, 20) + '...' : cupo.obs}</td>
                                <td data-titulo='Acciones'>
                                    <FaSearch onClick={() => handleShowModal(cupo.ctrl)}
                                        style={{ 'cursor': 'pointer' }} />&nbsp;&nbsp;|&nbsp;&nbsp;
                                    <FaPencilAlt onClick={() => { handleModificar(cupo.ctrl) }}
                                        style={{ 'cursor': 'pointer' }} />&nbsp;&nbsp;|&nbsp;&nbsp;
                                    <FaTrashAlt onClick={() => { handleEliminar(cupo.ctrl) }}
                                        style={{ 'cursor': 'pointer' }} />
                                </td>
                            </tr>
                        )
                    }) : null}
                </tbody>
            </table>
            {showModalDetalle ? /*Para que no se hagan fetchs */
                <ModalDetalleCupo
                    showModalDetalle={showModalDetalle}
                    handleCloseModal={handleCloseModal}
                    idCupoSeleccionado={idCupoSeleccionado}
                />
                : null}
        </div>
    )
}
