import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useAxios } from '../CustomHooks/useAxios';
import TicketCamion from './TicketCamion';
export default function ModalTicketsCamion({
    showModalTicketCamion,
    setShowModalTicketCamion,
    camionSeleccionado,
    setCamionSeleccionado
}) {
    const { response, loading, error } = useAxios({
        method: 'POST',
        url: '/api/camiones/tickets',
        headers: {
            accept: '*/*'
        },
        data: {
            camion: camionSeleccionado
        },
    });
    const handleCloseModal = () => {
        setShowModalTicketCamion(false);
        setCamionSeleccionado(null);
    };
    return (
        <>
            <Modal show={showModalTicketCamion}
                onHide={handleCloseModal}
                style={{ 'color': 'black' }}
                size="md">
                <Modal.Header
                    closeButton
                >
                    <Modal.Title className='ms-auto'>{`--- Tickets ----`}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='ms-auto me-auto'>
                    {loading ? (<h4>CARGANDO...</h4>) : (null)}
                    {error ? (<h4>Algo salio mal</h4>) : (null)}
                    {!loading && !error ?
                        response.tickets.map((t, index) => {
                            return (
                                <TicketCamion ticket={t} key={`ticket${index}`} />
                            )
                        }) : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
