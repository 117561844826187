import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import SelectCliente from '../components/Cupos/SelectCliente';
import SelectUniNeg from '../components/Cupos/SelectUniNeg';
import { useEmpresas } from '../components/CustomHooks/useEmpresas';
import { useAuth } from '../Contexts/AuthContext';
import { useUserConfig } from '../Contexts/UserConfigsProvider';
import { getStock } from '../api/empresaAPI';
import TablaStock from '../components/Empresa/TablaStock';
import Button from 'react-bootstrap/esm/Button';
import * as XLSX from 'xlsx';

export default function Stock() {
  const [values, setValues] = useState({ codempre: '', unineg: '' })
  const { empresas, loadingEmpresas, errorEmpresas } = useEmpresas();
  const { uniNegs } = useAuth();
  const [stock, setStock] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const {
    currenteEmpre,
    setCurrentEmpre,
    currentUnineg,
    setCurrentUnineg
  } = useUserConfig();

  const loadStock = async () => {
    if(values.codempre && values.unineg){
      try {
        setLoading(true)
        setError(false)
        const response = await getStock(values.codempre, values.unineg);
        setStock(response.data)
      } catch (error) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }
  }
  const valoresPorDefecto = async () => {
    if (empresas !== null && uniNegs !== null) {
      setValues({
        ...values,
        unineg: currentUnineg ? currentUnineg : uniNegs[0].ctrl,
        codempre: currenteEmpre ? currenteEmpre : empresas[0].codempre,
      })
    }
  };
  const handleChange = (e) => {
    if(e.target.name === 'codempre'){
      setCurrentEmpre(e.target.value);
    }else if(e.target.name === 'unineg'){
      setCurrentUnineg(e.target.value);
    } 
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }
  const handleBlur = (e) => {
    if(e.target.name === 'codempre'){
      setCurrentEmpre(e.target.value);
    }else if(e.target.name === 'unineg'){
      setCurrentUnineg(e.target.value);
    } 
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }
  const handleDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(stock);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "Stock.xlsx");
  };
  useEffect(() => {
    valoresPorDefecto()
    if (values.codempre && values.unineg) {
      loadStock()
    }
  }, [uniNegs, empresas])
  useEffect(()=>{
    loadStock()
  },[values])
  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col md={true}>
          <h4>Listado de stock</h4>
        </Col>
        <Col sm={true}>
          <Form.Label>Cliente</Form.Label>
          <SelectCliente handleChange={handleChange} handleBlur={handleBlur}
            nombre={'codempre'} value={values.codempre} empresas={empresas}
            loadingEmpresas={loadingEmpresas} errorEmpresas={errorEmpresas} />
        </Col>
        <Col sm={true}>
          <Form.Label>Unidad de Negocio</Form.Label>
          <SelectUniNeg uniNegs={uniNegs}
            handleChange={handleChange} handleBlur={handleBlur}
            nombre={'unineg'} value={values.unineg}
          />
        </Col>
        <Col lg={true}>
          <Form.Label>Generar Excel</Form.Label>
          <Button variant="success" onClick={handleDownloadExcel}>Exportar</Button>
        </Col>
      </Row>
      <br /><br />
      <TablaStock stock={stock} loading={loading} error={error} />
    </Container>
  )
}
