import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import TablaCuposCamion from './TablaCuposCamion';
export default function ModalCuposAsig({
    showModalCuposAsig,
    setShowModalCuposAsig,
    setCamionSeleccionado,
    camionSeleccionado,
    opcDesasig,
    setOpcDesasig,
    desAsigCamionCupo }) {
    const handleCloseModal = () =>{
        setShowModalCuposAsig(false);
        setCamionSeleccionado(null);
        setOpcDesasig(null);
    };
    return (
        <>
            <Modal show={showModalCuposAsig} onHide={handleCloseModal}
                style={{ 'color': 'black' }} size='lg'>
                <Modal.Header
                    closeButton
                    className="px-4">
                    <Modal.Title className='ms-auto'>{`--- Cupos asignados ----`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <TablaCuposCamion 
                        camionSeleccionado={camionSeleccionado} 
                        desAsigCamionCupo={desAsigCamionCupo}
                        opcDesasig={opcDesasig}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
