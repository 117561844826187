import {useState,useEffect} from 'react';
import { getListadoSinAisg } from '../../api/cuposAPI';

export function useFetchCuposSinAsig(uniNeg,codEmpre) {
    const [cupos, setCupos] = useState([]);
    const [loadingCupos, setLoadingCupos] = useState(false);
    const [errorCupos, setErrorCupos] = useState(null);
    
    const loadCuposSinAsig = async (uniNeg,codEmpre) => {
        try {
            setLoadingCupos(true)
            const response = await getListadoSinAisg( {uniNeg,codEmpre} )
            setCupos(response.data.resultado)
        } catch (error) {
            //console.log(error)
            setErrorCupos(error)
        }
        finally {
            setLoadingCupos(false)
        }
    }
    useEffect(() => {
        setLoadingCupos(true)
        loadCuposSinAsig( uniNeg,codEmpre)
    }, [uniNeg,codEmpre])

  return {cupos,loadingCupos,errorCupos,loadCuposSinAsig}
}
