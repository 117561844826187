import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import TableRowProductos from './TableRowProductos';
import { getProdClientes } from '../../../api/productosAPI';
export default function TablaProductos({ cliente,unineg, setRowsProductos, rowsProductos,
codorden }) {
    const [productosCliente, setProductos] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    /*Recupero los prod del cliente*/
    const loadProductos = async () => {
        try {
            const response = await getProdClientes(cliente,unineg)
            setProductos(response.data)
            /*Llamo a esta func para agregar lo que seria el primer
            renglon de la tabla si estoy creando un cupo, sino no agrego renglon*/
            if(codorden === undefined){
                addTableRows();
            }
        } catch (error) {
            console.log(error)
            setError(error)
        }
        finally {
            setLoading(false)
        }
    }
    
    const addTableRows = () => {
        const rowsInput = {
            producto: '',
            porcentaje: '',
        }
        setRowsProductos([...rowsProductos, rowsInput])
    }

    const deleteTableRows = (index) => {
        const rows = [...rowsProductos];
        rows.splice(index, 1);
        setRowsProductos(rows);
    }

    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const rowsInput = [...rowsProductos];
        rowsInput[index][name] = value;
        setRowsProductos(rowsInput);
    }

    useEffect(() => {
        setLoading(true)
        /*Si es una act no hago lo de limpiar los campos*/
        if(!(codorden !== undefined)){
            setRowsProductos(rowsProductos=[])
        }
        loadProductos()
    }, [cliente,unineg])
    return (
        <> 
            <Table striped bordered size="sm">
                <thead>
                    <tr>
                        <th>Producto</th>
                        <th>Porcentaje</th>
                        <th>Opciones</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? <tr><td colSpan={3}><h4>CARGANDO...</h4></td></tr> : null}
                    {error ? <tr><td colSpan={3}><h4>Algo salio mal</h4></td></tr> : null}
                    <TableRowProductos
                        rowsProductos={rowsProductos}
                        productosCliente={productosCliente}
                        addTableRows={addTableRows}
                        deleteTableRows={deleteTableRows}
                        handleChange={handleChange}
                        cliente={cliente}
                    />
                </tbody>
            </Table>
        </>
    )
}
