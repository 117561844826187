import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DetalleCupo from './DetalleCupo';
import { useAxios } from '../CustomHooks/useAxios';

export default function ModalDetalleCupo({ showModalDetalle, handleCloseModal, idCupoSeleccionado }) {
    const { response, loading, error } = useAxios({
        method: 'POST',
        url: '/api/cupo/detalle',
        headers: {
            accept: '*/*'
        },
        data: {
            codorden: idCupoSeleccionado
        },
    });

    return (
        <>
            <Modal show={showModalDetalle} onHide={handleCloseModal} style={{ 'color': 'black' }}>
                <Modal.Header
                    closeButton
                    className="px-4">
                    <Modal.Title className='ms-auto'>{`--- Detalle cupo ----`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (<h4>CARGANDO...</h4>) : (null)}
                    {error ? (<h4>Algo salio mal</h4>) : (null)}
                    {!loading && !error ?
                        <DetalleCupo detalle={response} />
                        : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
