import React from 'react';
import Form from 'react-bootstrap/Form';

export default function SelectDespachos({
    nombre, value, handleChange, handleBlur, despachos,
    loadingDespachos, errorDespachos,handleShowFrmEnvases,className }) {

    if (loadingDespachos) return <h4>CARGANDO...</h4>
    if (errorDespachos) return <h4>Algo salio mal</h4>

    return (
        <>
            <Form.Select aria-label="Tipo despacho" name={nombre} value={value}
                onChange={(e) => { handleChange(e); handleShowFrmEnvases(e) }} 
                onBlur={handleBlur} className={className}>
                {despachos ? despachos.map((despacho) => {
                    return (
                        <option value={despacho.coddespacho} key={`despacho${despacho.coddespacho}`} 
                        data-envase={despacho.envase}>
                            {despacho.nomdespacho}
                        </option>
                    )
                }) : null}
            </Form.Select>
        </>
    )
}
