import React from 'react'
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
export default function BodyTablaCamiones({ arr, setShowModalCuposSinAsig,
    setCamionSeleccionado, setShowModalCuposAsig, setOpcDesasig, setShowModalTicketCamion }) {
    const handleAsignar = (e, codcamion) => {
        setCamionSeleccionado(codcamion);
        setShowModalCuposSinAsig(true);
    };

    const handleDesAsignar = (e, codcamion) => {
        setCamionSeleccionado(codcamion);
        setShowModalCuposAsig(true);
        setOpcDesasig(true);
    };

    const handleVerCupo = (e, codcamion) => {
        setCamionSeleccionado(codcamion);
        setShowModalCuposAsig(true);
        setOpcDesasig(false);
    };

    const handleVerTicket = (e, codcamion) => {
        setShowModalTicketCamion(true);
        setCamionSeleccionado(codcamion);
    };
    return arr.map((c, index) => {
        return (
            <tr key={`camion${c.ctrl}${index}`}>
                <td data-titulo='ID'>{c.ctrl}</td>
                <td data-titulo='Cliente'>{String(c.txtempresa).trim()}</td>
                <td data-titulo='Patente chasis'>{c.patcha}</td>
                <td data-titulo='Patente acoplado'>{c.pataco}</td>
                <td data-titulo='Carga teórica'>{c.cargateorica}</td>
                <td data-titulo='Nombre chofer'>{c.nomchofer}</td>
                <td data-titulo='DNI'>{c.dni}</td>
                <td data-titulo='Ingreso'>{String(c.ingplaya).trimEnd().substring(0, 19).replace('T', ' ')}</td>
                <td data-titulo='Estado'>{c.nomestado}</td>
                <td data-titulo='Cupo'>{c.cupo_camion}</td>
                <td data-titulo='Acción'>
                    <ButtonGroup size="sm">
                        {
                            c.codestado === 1 ? 
                            <Button variant='warning' onClick={(e) => handleAsignar(e, c.ctrl)}>
                                Asingar
                            </Button> :
                                c.codestado === 2 ? <>
                                    <Button variant='success'onClick={(e) => handleVerCupo(e, c.ctrl)}>Ver cupo</Button>
                                    <Button variant='warning'onClick={(e) => handleAsignar(e, c.ctrl)}>Asingar</Button>
                                    <Button variant='danger' onClick={(e) => handleDesAsignar(e, c.ctrl)}>Desasignar</Button></> :
                                    c.codestado === 3 ? <Button variant='success' onClick={(e) => handleVerCupo(e, c.ctrl)}>Ver cupo</Button> :
                                        c.codestado === 4 ? <Button variant='success' onClick={(e) => handleVerTicket(e, c.ctrl)}>Ticket</Button> : null
                        }
                    </ButtonGroup>
                </td>
                {
                    /*c.codestado === 1 ? <a onClick={(e) => handleAsignar(e, c.ctrl)}>Asingar</a> :
                        c.codestado === 2 ? <>
                            <a onClick={(e) => handleVerCupo(e, c.ctrl)}>Ver cupo | </a>
                            <a onClick={(e) => handleAsignar(e, c.ctrl)}>Asingar | </a>
                            <a onClick={(e) => handleDesAsignar(e, c.ctrl)}>Desasignar </a>
                        </> :
                            c.codestado === 3 ? <a onClick={(e) => handleVerCupo(e, c.ctrl)}>Ver cupo</a> :
                                c.codestado === 4 ? <a onClick={(e) => handleVerTicket(e, c.ctrl)}>Ticket</a> : null
                */}
            </tr>
        )
    })
}
