import React from 'react'
import SelectProductos from './SelectProductos';
import Form from 'react-bootstrap/Form';

export default function TableRowProductos({ rowsProductos, addTableRows, 
    deleteTableRows, handleChange, productosCliente }) {
    return (
        rowsProductos.map((data, index) => {
            const { producto, porcentaje } = data; 
            return (
                <tr key={index}>
                    <td>
                        <SelectProductos productosCliente={productosCliente}
                            handleChange={handleChange}
                            index={index}
                            producto={producto}
                        />
                    </td>
                    <td>
                        <Form.Control type="number" step="0.1" min={0} max={100} value={porcentaje}
                            onChange={(evnt) => (handleChange(index, evnt))} name="porcentaje"
                        />
                    </td>
                    <td>
                        {index === 0 ?
                            <a onClick={() => addTableRows()}>Agregar</a>
                            :
                            <>
                                <a onClick={() => addTableRows()}>Agregar</a>
                                &nbsp; |  &nbsp;
                                <a onClick={() => deleteTableRows(index)}>Quitar</a>
                            </>
                        }
                    </td>
                </tr>
            )
        })
    )
}
