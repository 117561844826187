import React from 'react';
import Form from 'react-bootstrap/Form';

export default function SelectUniNeg({nombre, value, handleChange, handleBlur,
     uniNegs,className }) {
    return (
        <>
            <Form.Select aria-label="Tipo despacho" className={className}
            onChange={handleChange} onBlur={handleBlur} name={nombre} value={value}>
                {uniNegs ? uniNegs.map((unineg) => {
                    return (
                        <option value={unineg.ctrl} key={`uniNeg${unineg.ctrl}`}>
                            {unineg.unineg}
                        </option>
                    )
                }) : null}
            </Form.Select>
        </>
    )
}
