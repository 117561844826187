export default function DetalleCupo({detalle}) {    
    const {cupo,productos,envasesVacios} = detalle.detalleCupo;
    return (
        <div>
            <h5>Cabecera</h5>
            <ul>
                <li><b>Tipo: </b>{cupo[0].tipodesc}</li>
                <li><b>Tipo despacho: </b>{cupo[0].nomdespacho}</li>
                <li><b>Tipo envase: </b>{cupo[0].nombreenvase}</li>
                <li><b>Carga máxima: </b>{cupo[0].cantidad}</li>
                <li><b>Observaciones: </b>{cupo[0].obs}</li>
            </ul>
            <h5>Items</h5>
            <ul>
                {productos.length !==0?productos.map((p,index)=>{return(
                    <li key={`prod${p.ctrl}${index}`}>
                        <b>{p.nompro}</b>
                        {p.porcentaje} %
                    </li>
                )}):null}
            </ul>
            {envasesVacios.length !== 0?
            <>
            <h5>Envases vacios</h5>
            <ul>
                {envasesVacios.length !== 0?envasesVacios.map((e,index)=>{return(
                    <li key={`env${e.ctrlenvase}${index}`}>
                        <b>{e.nombre}</b>
                        {e.unidades} U
                    </li>
                )}):null}
            </ul>
            </>:null}
        </div>
    )
}
