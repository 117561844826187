import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import SelectCliente from '../components/Cupos/SelectCliente';
import SelectUniNeg from '../components/Cupos/SelectUniNeg';
import { useNavigate } from "react-router-dom";
import { useEmpresas } from '../components/CustomHooks/useEmpresas';
import { useAuth } from '../Contexts/AuthContext';
import {useUserConfig} from '../Contexts/UserConfigsProvider';
import TablaCuposSinAig from '../components/Cupos/TablaCuposSinAig';
export default function Cupos() {
  const [values, setValues] = useState({ uniNeg: '', codEmpre: '' })
  const navigate = useNavigate();
  const { empresas, loadingEmpresas, errorEmpresas } = useEmpresas();
  const { uniNegs } = useAuth();
  const {
    currenteEmpre,
    setCurrentEmpre,
    currentUnineg,
    setCurrentUnineg
  } = useUserConfig();
  const valoresPorDefecto = async()=>{
    if(empresas !== null && uniNegs !== null ){
      setValues({
        ...values,
        uniNeg: currentUnineg ? currentUnineg : uniNegs[0].ctrl,
        codEmpre: currenteEmpre ? currenteEmpre : empresas[0].codempre,
      })
    }
  };
  useEffect(()=>{
    valoresPorDefecto()
  },[uniNegs,empresas])
  const handleNuevo = () => {
    navigate('/cupos/alta');
  }
  const handleChange = (e) => {
    if(e.target.name === 'codEmpre'){
      setCurrentEmpre(e.target.value);
    }else{
      setCurrentUnineg(e.target.value);
    }   
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }
  const handleBlur = (e) => {
    if(e.target.name === 'codEmpre'){
      setCurrentEmpre(e.target.value);
    }else if(e.target.name === 'uniNeg'){
      setCurrentUnineg(e.target.value);
    } 
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }
  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col md={true}>
          <h4>Listado de cupos disponibles</h4>
        </Col>
        <Col sm={true}>
          <Form.Label>Cliente</Form.Label>
          <SelectCliente handleChange={handleChange} handleBlur={handleBlur}
            nombre={'codEmpre'} value={values.codEmpre} empresas={empresas}
            loadingEmpresas={loadingEmpresas} errorEmpresas={errorEmpresas} />
        </Col>
        <Col sm={true}>
          <Form.Label>Unidad de Negocio</Form.Label>
          <SelectUniNeg uniNegs={uniNegs}
            handleChange={handleChange} handleBlur={handleBlur}
            nombre={'uniNeg'} value={values.uniNeg}
          />
        </Col>
        <Col lg={true}>
          <hr style={{ color: '#1a202c' }} />{/* Para nivelar los btn de limpiar cupos y nuevo
          y que quede separado en celular*/}
          <Button variant="danger">Limpiar Cupos</Button>
          {' '}<div className="vr" />{' '}
          <Button variant="success" onClick={handleNuevo}>Nuevo</Button>
        </Col>
      </Row>
      <br /><br />
      <TablaCuposSinAig uniNeg={values.uniNeg} codEmpre={values.codEmpre}/>
    </Container>
  )
}
