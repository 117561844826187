import {useState,useEffect} from 'react';
import { getDespachos } from '../../api/despachosAPI';

export function useDespachos(coddespacho) {
    const [despachos, setDespachos] = useState(null);
    const [loadingDespachos, setLoadingDespachos] = useState(false);
    const [errorDespachos, setErrorDespachos] = useState(null);
    
    const loadDespachos = async (coddespacho) => {
        try {
            const response = await getDespachos({ coddespacho })
            setDespachos(response.data)
        } catch (error) {
            console.log(error)
            setErrorDespachos(error)
        }
        finally {
            setLoadingDespachos(false)
        }
    }
    useEffect(() => {
        setLoadingDespachos(true)
        loadDespachos(coddespacho)
    }, [coddespacho])

  return {despachos,loadingDespachos,errorDespachos,loadDespachos}
}
