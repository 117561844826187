import axios from "axios";
import {BASE_URL} from '../config';

axios.defaults.withCredentials = true;
// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    if(error.response.status === 401) window.location.href = '/';
    return Promise.reject(error);
  });
/*Envio el ctrlEmpresa que tiene determinado el usuario, en el front si es 0
puede ver todas, si tiene un id determinado, solo puede ver esa. En el backend
si es 0 se pasa como null para que el SP retorne todas las empre */
export const getEmpresasCliente = async (codempre) => {
    return await axios.post(`${BASE_URL}/api/clientes/clientes-empresas`, codempre);
};
