import axios from "axios";
import {BASE_URL} from '../config';

axios.defaults.withCredentials = true;
// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    if(error.response.status === 401) window.location.href = '/';
    return Promise.reject(error);
  });
/*POST a la API para dar de alta el cupo si y solo si, se cumplen todas las
validaciones de stock que hay en el backend*/
export const postCupo = async (mergedCupo) => {
    return await axios.post(`${BASE_URL}/api/cupos/crear`, mergedCupo);
};
/*Consulta a la API para obtener el listado de cupo de la empresa por unineg*/
export const getListadoSinAisg = async (filtros) => {
    return await axios.post(`${BASE_URL}/api/cupos`, filtros);
};
/*Consulta para ver el detalle de un cupo desde el listado
se ejecuta cuando se toca la lupita en la lista de cupos */
export const getDetalleCupo = async(codorden) => {
    return await axios.post(`${BASE_URL}/api/cupo/detalle`,codorden);
};
/*Consulta para actualizar un cupo */
export const updateCupo = async(mergedCupo) =>{
    return await axios.post(`${BASE_URL}/api/cupos/editar`, mergedCupo);
};
/*Consulta para eliminar un cupo por su ID */
export const deleteCupo = async(codorden)=>{
    return await axios.post(`${BASE_URL}/api/cupos/eliminar`,{codorden});
};