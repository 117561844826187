import axios from "axios";
import {BASE_URL} from '../config';

axios.defaults.withCredentials = true;

/*Envio el userName, si el sp lo encuentra, me devuelve las coordenadas
que le tengo que pedir al usuario para el inicio de sesion */
export const requestCoordenadas = async (userName) => {
    return await axios.post(`${BASE_URL}/api/auth/request-coordenadas`, userName);
};

/*Recibo la respuesta del backend para analizar si las coordenadas
ingresadas son las correctasa*/
export const compararCoordenadas = async (coordenadas) => {
    return await axios.post(`${BASE_URL}/api/auth/comparar-coordenadas`,coordenadas)
};

/*Llamda al endpoint que verifica el JWT que puede tener el usuario 
apenas entra a la APP */
export const IsLogged = async () => {
    return await axios.get(`${BASE_URL}/api/auth/verifyuser`)
};

export const logoutRequest = async () => {
    return await axios.post(`${BASE_URL}/api/user/logout`)
};
  