import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavBarCustom from './components/Nav/NavBarCustom';
import Camiones from './pages/Camiones';
import Stock from './pages/Stock';
import Cupos from './pages/Cupos';
/*import Home from './components/Home';*/
import Login from './pages/Login';
import FrmGenCupo from './components/Cupos/FrmGenCupo';
import { useAuth } from './Contexts/AuthContext';

function App() {
  const { auth} = useAuth();
  return (
    <BrowserRouter>
        <>
        {auth ?
          <>
            <header>
              <NavBarCustom />
            </header>
            <Routes>
              <Route path='/' element={<Camiones />} />
              <Route path='/camiones' element={<Camiones />} />
              <Route path='/cupos' element={<Cupos />} />
              <Route path='/cupos/alta' element={<FrmGenCupo />} />
              <Route path='/cupos/modificar/:codorden' element={<FrmGenCupo />} />
              <Route path='/stock' element={<Stock />} />
            </Routes>
          </>
          :
          <>
          <Routes>       
            <Route path='/' element={<Login />} />
          </Routes>          
          </>
        }
        </>
    </BrowserRouter>
  );
}

export default App;
