import React, { useEffect, useState } from 'react'
import { getCuposAsignados } from '../../api/camionesAPI';
import Button from 'react-bootstrap/Button';

export default function TablaCuposCamion({ camionSeleccionado, opcDesasig, desAsigCamionCupo }) {
    const [loading,setLoading]=useState(false);
    const [error,setError]=useState(false);
    const [cupos,setCupos]=useState([]);

    const loadCuposAsignados = async () => {
        setLoading(true);
        try {
            const response = await getCuposAsignados(camionSeleccionado);
            setCupos(response.data)
        } catch (error) {
            setError(true)
        } finally {
            setLoading(false)
        }
    };

    useEffect(()=>{
        loadCuposAsignados()
    },[])

    const handleDesAsignar = (e, ctrl) => {
        const confirma = window.confirm("¿Confirma que quiere desvincular el cupo al camion?");
        if (confirma) {
            desAsigCamionCupo(ctrl)
        }
    };
    if(loading)return<h4>CARGANDO...</h4>
    if(error)return<h4>ALGO SALIO MAL</h4>
    return (
        <>
            <table className="table table-striped table-light table-sm">
                <thead className='table-dark'>
                    <tr>
                        <td scope='col'>Carga máxima</td>
                        <td scope='col'>Fecha Gen.</td>
                        <td scope='col'>Mezcla</td>
                        <td scope='col'>Tipo despacho</td>
                        <td scope='col'>Observaciones</td>
                        {opcDesasig ?
                            <td scope='col'>Desasignar</td>
                            : null}
                    </tr>
                </thead>
                <tbody>
                    {!loading && !error ?
                        cupos.map((c, index) => {
                            return (
                                <tr key={`cupoCamion${c.ctrl}${index}`}>
                                    <td data-titulo='Carga máxima'>{c.cantidad}</td>
                                    <td data-titulo='Fecha Gen.'>{String(c.fechagen).trimEnd().substring(0, 19).replace('T', ' ')}</td>
                                    <td data-titulo='Mezcla'>{String(c.mezcla).trim()}</td>
                                    <td data-titulo='Tipo despacho'>{c.tipo}</td>
                                    <td data-titulo='Observaciones'>{
                                        String(c.obs).trim()
                                    }</td>
                                    {opcDesasig ?
                                        <td data-titulo='Desasignar'>
                                            <Button variant='danger' onClick={(e) => handleDesAsignar(e, c.ctrl)}>Desasignar</Button> 
                                        </td>
                                        : null}
                                </tr>
                            )
                        })
                        : null}
                </tbody>
            </table>
        </>
    )
}
