import { useState, useEffect } from 'react';
import axios from 'axios';
import {BASE_URL} from '../../config';

axios.defaults.baseURL = BASE_URL;
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if(error.response.status === 401) window.location.href = '/';
  return Promise.reject(error);
});
export const useAxios = (axiosParams) => {
    const [response, setResponse] = useState(undefined);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    const fetchData = async (params) => {
      try {
       const result = await axios.request(params);
       setResponse(result.data);
       } catch( error ) {
         setError(error);
       } finally {
         setLoading(false);
       }
    };

    useEffect(() => {
        fetchData(axiosParams);
    }, []); // execute once only

    return { response, error, loading };
};