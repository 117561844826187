import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Modal from 'react-bootstrap/Modal';

export default function ModalCargando({esperandoRtaBackend}) {
    return (
        <>
            <Modal show={esperandoRtaBackend} style={{ 'color': 'black' }}>
                <Modal.Header>
                    <Modal.Title>Cargando, aguarde un momento.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>
                        El servidor esta procesando la información.
                    </h4>
                    <ProgressBar animated now={100} variant="success"/>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    )
}
