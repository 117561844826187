import React, { useState } from 'react';
import { useFormik } from 'formik';
import { userNameSchema } from '../../schemas/userNameSchema';
import { requestCoordenadas } from '../../api/authAPI';
import '../../formStyles.css'
export default function UserName({setCoordenadas, setTarjeta}) {
    const [esperando, setEsperando] = useState(true);
    const [respuesta, setRespuesta] = useState('');
    const [alertType, setAlertType] = useState(null);
    const onSubmit = async (values, actions) => {
        setEsperando(true);
        try {
            const response = await requestCoordenadas(values); //response.data.exist
            if (response.data.length === 1){
                //No se encontro al usuario
                setAlertType("alert alert-danger");
                setRespuesta('Error');
                setEsperando(false);
            }else{
                //Se encontro al usuario
                setCoordenadas(response.data[1])
                setTarjeta(response.data[0][0].ctrltarjeta)
            }
        } catch (error) {
            if (error.response) {
                setAlertType("alert alert-danger");
                setRespuesta(error.response.data.msj);
                setEsperando(false);
            }
        }
        actions.resetForm();
    };

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
    } = useFormik({
        initialValues: {
            userName: "",
        },
        validationSchema: userNameSchema,
        onSubmit,
    });
    return (
        <>
            {esperando ?
                null
                :
                <div className={alertType} role="alert" style={{ textAlign: "center" }}>
                    <b>
                        {respuesta}
                    </b>
                </div>
            }
            <form onSubmit={handleSubmit} className="form">
                <label className='form-label'>Usuario</label>
                <input
                    type="text"
                    placeholder="Usuario"
                    name="userName"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.userName && touched.userName ? "input-error customInput" : "customInput"}
                />
                {errors.userName && touched.userName && (
                    <p className="error">{errors.userName}</p>
                )}
                <button className='customBtn' type="submit" disabled={isSubmitting}>
                    Ingresar
                </button>
            </form>
        </>
    )
}
