import React from 'react'
import Form from 'react-bootstrap/Form';

export default function SelectProductos({ productosCliente, producto, handleChange, index }) {
    
    return (
        <Form.Select aria-label="Producto" name={'producto'}
            onChange={(evnt)=>(handleChange(index, evnt))} value={producto}>
                <option key={`productoDefault`} value=''>Seleccione un producto</option>
            {productosCliente ? productosCliente.map((e) => {
                return (
                    <option key={`producto${e.codpro}`} value={e.codpro}>{e.nompro}</option>
                )
            }):null}
        </Form.Select>
    )
}
