import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import TablaCuposSinAsigModal from './TablaCuposSinAsigModal';

export default function ModalCupoSinAsig({ 
        showModalCuposSinAsig, setShowModalCuposSinAsig,
        setCamionSeleccionado,codempre,unineg,asigCamionCupo }) {
    const handleCloseModal = () =>{
        setShowModalCuposSinAsig(false);
        setCamionSeleccionado(null);
    };
    return (
        <>
            <Modal show={showModalCuposSinAsig} 
                onHide={handleCloseModal} 
                style={{ 'color': 'black'}}
                size="lg">
                <Modal.Header
                    closeButton
                    >
                    <Modal.Title className='ms-auto'>{`--- Cupos disponibles ----`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>                 
                    <TablaCuposSinAsigModal codempre={codempre} unineg={unineg}
                     asigCamionCupo={asigCamionCupo}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
