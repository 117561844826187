import axios from "axios";
import {BASE_URL} from '../config';

axios.defaults.withCredentials = true;
axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if(error.response.status === 401) window.location.href = '/';
    return Promise.reject(error);
  });
/*Esta consulta me permite obtener un listado de camiones por uninegs estados
y emmpresa */
export const getCamionesEnPlayaEmpre = async (filtros) => {
    return await axios.post(`${BASE_URL}/api/camiones/camiones-playa`, {filtros});
};

/*Consulta para asignar un cupo a un camion */
export const asignarCamionCupo = async(ctrl,codcamion,cantidad)=>{
  return await axios.post(`${BASE_URL}/api/camiones/vincular-camion-cupo`,{
    ctrl,
    codcamion,
    cantidad
  })
};

/*Consulta para obtener los cupos que tiene asignado un camion */
export const getCuposAsignados=async(codcamion)=>{
  return await axios.post(`${BASE_URL}/api/camiones/cupos-asignados`,{codcamion})
};

/*Consluta para desasignar un cupo de un camion */
export const apiDesAsigCamionCupo=async(ctrl,codcamion)=>{
  return await axios.post(`${BASE_URL}/api/camiones/desasignar-cupo-camion`,{
    ctrl,
    codcamion
  })
};

/*Consluta para obtener los tickets de un camion */
export const getTicketsCamion=async(camion)=>{
  return await axios.post(`${BASE_URL}/api/camiones/tickets`,{
    camion
  })
};