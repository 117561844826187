import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../../assets/logo_terminales_servicios.png';
import { LinkContainer } from 'react-router-bootstrap';
import { logoutRequest } from '../../api/authAPI';
import { useAuth } from '../../Contexts/AuthContext';

export default function NavBarCustom() {
    const {setAuth, usuario} = useAuth();
    async function handleLogout() {
        logoutRequest()
          .then(setAuth(false))
      }
    return (
        <Navbar bg="light" expand="lg" variant='light'>
            <Container fluid>
                <LinkContainer to='/camiones'>
                    <Navbar.Brand style={{ "paddingRight": "15px"}}>
                        <img src={Logo} alt="logo" width={40} height={40} className="d-inline-block mb-auto" />
                        {' '}TERMINALES Y SERVICIOS
                    </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" style={{'width':''}}/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <LinkContainer to='/camiones'>
                            <Nav.Link className='active'>Camiones</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to='/cupos'>
                            <Nav.Link className='active'>Cupos</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to='/stock'>
                            <Nav.Link className='active'>Stock</Nav.Link>
                        </LinkContainer>
                    </Nav>
                    <Nav className='ms-auto'>
                        <NavDropdown title={usuario} id="basic-nav-dropdown" active>
                            <NavDropdown.Item href="/" onClick={handleLogout}>Cerrar sesión</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

