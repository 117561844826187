import React from 'react';
import Form from 'react-bootstrap/Form';

export default function SelectEstadosCamion({ nombre, value, handleChange }) {
    const estados = [
        { idEstado: 0, estado: 'Todos' },
        { idEstado: 4, estado: 'Finalizado' },
        { idEstado: 3, estado: 'Operando' },
        { idEstado: 1, estado: 'Playa sin orden' },
        { idEstado: 2, estado: 'Playa con orden' },
    ]
    return (
        <>
            <Form.Select aria-label="Estado" name={nombre} value={value}
                onChange={handleChange}>
                {estados.map((e) => {
                    return (
                        <option value={e.idEstado} key={`estado${e.idEstado}`}>
                            {e.estado}
                        </option>
                    )
                })}
            </Form.Select>
        </>
    )
}
