import {useEffect, useState} from 'react'
import { useAuth } from '../../Contexts/AuthContext';
import { getEmpresasCliente } from '../../api/clienteAPI';

export function useEmpresas() {
    const [empresas, setEmpresas] = useState(null);
    const [loadingEmpresas, setLoading] = useState(false);
    const [errorEmpresas, setError] = useState(null);
    const { codempre } = useAuth();

    const loadData = async () => {
        try {
            const response = await getEmpresasCliente({ codempre })
            setEmpresas(response.data)
        } catch (error) {
            setError(error)
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        setLoading(true)
        loadData()
    }, [])
  return {empresas,loadingEmpresas,errorEmpresas};
}
