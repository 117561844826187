import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TablaProductos from '../Cupos/TablaProductos/TablaProductos';
import SelectCliente from './SelectCliente';
import SelectUniNeg from './SelectUniNeg';
import SelectDespachos from './SelectDespachos';
import ModalCargando from '../ModalCargando';
import { useEmpresas } from '../CustomHooks/useEmpresas';
import { useAuth } from '../../Contexts/AuthContext';
import {useUserConfig} from '../../Contexts/UserConfigsProvider';
import { useDespachos } from '../CustomHooks/useDespachos';
import { useValidateTables } from '../CustomHooks/useValidateTables';
import { genCupoSchema } from '../../schemas/genCupoSchema';
import { getEnvCliente } from '../../api/envasesAPI';
import { postCupo, getDetalleCupo, updateCupo } from '../../api/cuposAPI';
export default function FrmGenCupo() {
    const navigate = useNavigate();
    const { codorden } = useParams();
    const {
        currenteEmpre,
        setCurrentEmpre,
        currentUnineg,
        setCurrentUnineg
      } = useUserConfig();
    const [esperandoRtaBackend, setEsperandoRtaBackEnd] = useState(false);
    const [errorAltaAct, setErrorAltaAct] = useState(false);
    const [errorRespuesta, setErrorRespuesta] = useState([]);
    const [errorRecuperarCupo, setErrorRecuperarCupo] = useState(false);
    const [showTableProductos, setshowTableProductos] = useState(false);
    const [rowsProductos, setRowsProductos] = useState([]);
    const [pesoEnvase, setPesoEnvase] = useState(null);
    const [envasesCliente, setEnvases] = useState(null);
    const [loadingEnvases, setLoadingEnvases] = useState(false);
    const [errorEnvases, setErrorEnvases] = useState(null);
    const [disableEnvases, setDisableEnvases] = useState(false);
    //Estados para las validaciones
    const [valiProductos, setValiProductos] = useState([]);
    /*Custom hooks*/
    const { empresas, loadingEmpresas, errorEmpresas } = useEmpresas();
    const { despachos, loadingDespachos, errorDespachos, loadDespachos } = useDespachos();
    const { validarTablaProductos } = useValidateTables();
    const { uniNegs } = useAuth();
    const [valores, setValores] = useState({
        cliente: '',
        uniNeg: '',
        tipoOrden: 'D',
        tipoDespacho: '',
        envase: '',
        cargaMaxima: '',
        conCoating: false,
        observaciones: '',
        cantCuposIguales: 1
    });
    const onSubmit = async () => {
        try {
            setEsperandoRtaBackEnd(true);
            setErrorRespuesta([])
            setValiProductos([])
            const rta = await validarTablas()//Valido las tablas prod y env
            if (!rta){
                setErrorAltaAct(true);
                setErrorRespuesta([
                    {error:'Hay errores en la sección de productos.'},
                    {error:'Los errores se marcan en rojo a lo largo del formulario.'}
                ]);
                return//Hay errores, return para cortar
            } 
            let mergedCupo = mergeCupo();
            let response;
            if (codorden === undefined) {
                //Alta cupo
                response = await postCupo({ mergedCupo });
            } else {
                //Mod cupo
                mergedCupo.cupo.ctrl = parseInt(codorden, 10)
                response = await updateCupo({ mergedCupo })
            }
            if (response.status === 201) {
                toast.success('Datos guardados con exito!!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                limpiarCampos();
                setErrorAltaAct(false);
            } else {
                setErrorAltaAct(true);
                setErrorRespuesta(response.data.erroresCupo);
            }
        } catch (error) {
            setErrorAltaAct(true);
            setErrorRespuesta([{error:'Error interno del servidor'}])
        } finally {
            setEsperandoRtaBackEnd(false);
            window.scrollTo(0, 0);
        }
    };

    const mergeCupo = () => {
        const mergedCupo = {
            cupo: values,
            productos: rowsProductos,
        };
        mergedCupo.cupo.pesoEnvase = pesoEnvase;
        return mergedCupo;
    }

    const validarTablas = async () => {
        let validacionProductos = [];
        validacionProductos = await validarTablaProductos(rowsProductos)
        if (!(validacionProductos.length === 0)) {
            setValiProductos(validacionProductos)
        }
        if (validacionProductos.length !== 0) {
            //Existen errores
            return false
        } else {
            //No hay errores
            return true
        }
    }

    /*Recupero los envases del cliente*/
    const loadEnvases = async (cliente) => {
        try {
            const response = await getEnvCliente({ cliente })
            setEnvases(response.data)
        } catch (error) {
            //console.log(error)
            setErrorEnvases(error)
        }
        finally {
            setLoadingEnvases(false)
        }
    };
    const handlePesoEnvase = (e) => {
        const pesoEnvase = e.target.selectedOptions[0].getAttribute('data-pesoenvase');
        setPesoEnvase(pesoEnvase);
    };
    const handleCurrent = (e)=>{
        if(e.target.name === 'cliente'){
            setCurrentEmpre(e.target.value);
        }else{
            setCurrentUnineg(e.target.value);
        }
    };
    const handleShowFrmEnvases = (e) => {
        const seVaEnvasado = e.target.selectedOptions[0].getAttribute('data-envase')
        if (seVaEnvasado == 'N') {
            setDisableEnvases(true)
            /*Lo seteo en -1 de esta forma para que no se refrezquen
             los values y para que no salte la validacion de no 
             tener un envase selecciona*/
            setFieldValue('envase', -1)
        }
        else { setDisableEnvases(false) }
    };
    const limpiarCampos = () => {
        setDisableEnvases(false)
        values.uniNeg = uniNegs ? uniNegs[0].ctrl : ''
        values.tipoDespacho = despachos ? despachos[0].coddespacho : ''
        values.envase = ''
        values.cargaMaxima = ''
        values.observaciones = ''
        values.cantCuposIguales = 1
    };

    const buscarCupoMod = async () => {
        try {
            const response = await getDetalleCupo({ codorden });
            return response
        } catch (error) {
            setErrorRecuperarCupo(true);
            return error
        }
    };
    /*Esta funcion contiene la logica para saber que valores
    cargar en base a si se trata de un cupo nuevo o la modificacion de uno*/
    const loadValoresCupo = async () => {
        let valorInicial = {}
        if (codorden) {
            const resultado = await buscarCupoMod();
            if (!(resultado instanceof Error)) {
                const { cupo, productos } = resultado.data.detalleCupo;
                setRowsProductos([...productos])
                if (cupo[0].tipenvase === -1) {
                    setDisableEnvases(true)
                    /*Lo seteo en -1 de esta forma para que no se refrezquen
                     los values y para que no salte la validacion de no 
                     tener un envase selecciona*/
                    setFieldValue('envase', -1)
                }
                else { setDisableEnvases(false) }
                valorInicial = {
                    cliente: cupo ? cupo[0].codempre : '',
                    uniNeg: cupo ? cupo[0].unineg : '',
                    tipoOrden: cupo ? cupo[0].tipo : '',
                    tipoDespacho: cupo ? cupo[0].coddespacho : '',
                    envase: cupo ? cupo[0].tipenvase : '',
                    cargaMaxima: cupo ? cupo[0].cantidad : '',
                    conCoating: cupo ? cupo[0].couting : '',
                    observaciones: cupo ? cupo[0].obs : '',
                    cantCuposIguales: 1/*Queda hardcodeado en 1 porque solo se esta
                    editando un cupo*/
                }
                setValores(valorInicial);
            }
        }
        setshowTableProductos(true);
    };

    let valoresInicialesCupoNuevo = {
        cliente: currenteEmpre ? currenteEmpre : empresas ? empresas[0].codempre : '',
        uniNeg: currentUnineg ? currentUnineg : uniNegs ? uniNegs[0].ctrl : '',
        tipoOrden: 'D',
        tipoDespacho: despachos ? despachos[0].coddespacho : '',
        envase: '',
        cargaMaxima: '',
        conCoating: false,
        observaciones: '',
        cantCuposIguales: 1
    }

    const {
        setFieldValue,
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
    } = useFormik({
        enableReinitialize: true,
        initialValues: codorden ? valores : valoresInicialesCupoNuevo,
        validationSchema: genCupoSchema,
        onSubmit,
    });

    useEffect(() => {
        limpiarCampos()
        setLoadingEnvases(true)
        loadEnvases(values.cliente)
        loadDespachos()
        loadValoresCupo()
    }, [values.cliente])

    if (errorRecuperarCupo) {
        return (<h4>Algo salio mal</h4>)
    }
    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                pauseOnHover
                theme="colored"
            />
            <ModalCargando esperandoRtaBackend={esperandoRtaBackend} />
            {errorAltaAct ? <Alert key='danger' variant='danger' autoFocus>
                Los siguientes errores impidieron guardar los datos:
                <ul>
                    {errorRespuesta.map((err, index) => {
                        return (<li key={`errRtaServer${index}`}>{err.error}</li>)
                    })}
                </ul>
            </Alert> : null}
            <Form style={{
                'backgroundColor': 'white',
                'color': 'black',
                'margin': '1rem',
                'border': 'solid',
                'borderWidth': '1px 3px 3px 0em',
                'borderColor': 'gray',
                'borderRadius': '6px',
                'padding': '2%'
            }} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Col sm={true}>
                        <Form.Label>Cliente</Form.Label>
                        <SelectCliente handleChange={(e) => { handleChange(e); handleCurrent(e) }} handleBlur={handleBlur}
                            nombre={'cliente'} value={values.cliente} empresas={empresas}
                            loadingEmpresas={loadingEmpresas} errorEmpresas={errorEmpresas}
                            codorden={codorden}
                            className={errors.cliente && touched.cliente ? "input-error" : ""}
                        />
                        {errors.cliente && touched.cliente && (
                            <p className="error">{errors.cliente}</p>
                        )}
                    </Col>
                    <Col sm={true}>
                        <Form.Label>Unidad de Negocio</Form.Label>
                        <SelectUniNeg uniNegs={uniNegs}
                            handleChange={(e) => { handleChange(e); handleCurrent(e) }} handleBlur={handleBlur}
                            nombre={'uniNeg'} value={values.uniNeg}
                            className={errors.uniNeg && touched.uniNeg ? "input-error" : ""}
                        />
                        {errors.uniNeg && touched.uniNeg && (
                            <p className="error">{errors.uniNeg}</p>
                        )}
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col lg={true}>
                        <Form.Label as="legend" column sm={2}>
                            Tipo orden
                        </Form.Label>
                        <Form.Check
                            type="radio"
                            label="Despacho"
                            name="tipoOrden"
                            value='D'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            checked={values.tipoOrden === 'D' ? true : false}
                        />
                        <Form.Check
                            type="radio"
                            label="Recepción"
                            name="tipoOrden"
                            value='R'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            checked={values.tipoOrden === 'R' ? true : false}
                        />
                    </Col>
                    <Col lg={true}>
                        <Form.Label>Tipo despacho</Form.Label>
                        <SelectDespachos handleChange={handleChange} handleBlur={handleBlur}
                            nombre={'tipoDespacho'} value={values.tipoDespacho} despachos={despachos}
                            loadingDespachos={loadingDespachos} errorDespachos={errorDespachos}
                            handleShowFrmEnvases={handleShowFrmEnvases}
                            className={errors.tipoDespacho && touched.tipoDespacho ? "input-error" : ""} />
                        {errors.tipoDespacho && touched.tipoDespacho && (
                            <p className="error">{errors.tipoDespacho}</p>
                        )}
                    </Col>
                    <Col lg={true}>
                        <Form.Label>Envase</Form.Label>
                        <Form.Select aria-label="Envase" name={'envase'}
                            onChange={(e) => { handleChange(e); handlePesoEnvase(e) }}
                            value={values.envase}
                            disabled={disableEnvases}
                            className={errors.envase && touched.envase ? "input-error" : ""}>
                            <option key={`envaseDefaultFrm`} value=''>
                                {loadingEnvases ? 'Cargando':
                                errorEnvases ? 'Algo salió mal': 'Seleccione un envase'}
                            </option>
                            {envasesCliente ? envasesCliente.map((e) => {
                                return (
                                    <option
                                        key={`envaseFrm${e.ctrl}`}
                                        value={e.ctrl}
                                        data-pesoenvase={e.peso}
                                    >
                                        {e.nombre}
                                    </option>
                                )
                            }) : null}
                        </Form.Select>
                        {errors.envase && touched.envase && (
                            <p className="error">{errors.envase}</p>
                        )}
                    </Col>
                    <Col lg={true}>
                        <Form.Label>Carga máxima</Form.Label>
                        <Form.Control
                            type="number"
                            name='cargaMaxima'
                            value={values.cargaMaxima}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.cargaMaxima && touched.cargaMaxima ? "input-error" : ""}
                        />
                        {errors.cargaMaxima && touched.cargaMaxima && (
                            <p className="error">{errors.cargaMaxima}</p>
                        )}
                        {disableEnvases ? <p>Kilos</p> : <p>Unidades</p>}
                    </Col>
                    <Col id="formGridCheckbox" lg={true}>
                        <Form.Label>Despacho con coating</Form.Label>
                        <Form.Check type="checkbox" inline value={values.conCoating}
                            name='conCoating' checked={values.conCoating}
                            onChange={handleChange}
                            onBlur={handleBlur} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Observaciones</Form.Label>
                        <Form.Control as="textarea" rows={3} name='observaciones'
                            value={values.observaciones} onChange={handleChange}
                            onBlur={handleBlur} />
                    </Form.Group>
                </Row>
                {/*Tabla Productos*/}
                {showTableProductos ?
                    <TablaProductos cliente={values.cliente} unineg={values.uniNeg} rowsProductos={rowsProductos}
                        setRowsProductos={setRowsProductos} codorden={codorden} /> : null}
                {valiProductos ?
                    <Row xs="auto">
                        {valiProductos.map((errProd, index) => {
                            return (
                                <Col key={`errorProd${index}`}>
                                    <p className="error" >{errProd.error}</p>
                                </Col>
                            )
                        })}
                    </Row>
                    : null
                }
                {!codorden ? <Row className="mb-3">
                    <Col lg={true}>
                        <Form.Label>
                            Cantidad cupos iguales
                        </Form.Label>
                        <Form.Control type="number" style={{ 'width': '15%' }} name="cantCuposIguales"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cantCuposIguales} />
                    </Col>
                </Row> : null}
                <Row className='mb-1'>
                    <Col sm={1} className='mb-1'>
                        <Button variant="secondary" type="button" onClick={()=>navigate('/cupos')}>
                            Cancelar
                        </Button>
                    </Col>
                    {' '}
                    <Col sm={1}>
                        {/*<Button variant="primary" disabled={isSubmitting} type="submit">
                                Guardar
                            </Button>*/}
                        <Button variant="primary" disabled={isSubmitting} type="submit">
                            {isSubmitting ?
                                "Cargando..." : "Guardar"
                            }
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}