export function useValidateTables() {
  const validarTablaProductos = async (productos) => {
    let porcentajeAcum = 0;
    let porcentajeParseado = 0;
    let auxErrProds = [];
    productos.map((p, index) => {
      //Validaciones productos        
      if (p.producto === '') {
        auxErrProds.push({
          error: 'Hay productos sin seleccionar',
          campo: 'producto',
          index
        })
      } else if (isNaN(parseInt(p.producto, 10))) {
        auxErrProds.push({
          error: 'El ID del producto no es un numero',
          campo: 'producto',
          index
        })
      }
      //Validaciones porcentajes
      if (p.porcentaje === '') {
        auxErrProds.push({
          error: 'Inserte un porcentaje válido',
          campo: 'porcentaje',
          index
        })
      }
      //Caso en el que no es un numero
      if (isNaN(parseFloat(p.porcentaje))) {
        auxErrProds.push({
          error: 'El porcentaje debe ser numerico',
          campo: 'porcentaje',
          index
        })
      } else {
        //Aca ya se que lo puedo convertir a float
        porcentajeParseado = parseFloat(p.porcentaje);
        //Esto se valida en el input, pero por las dudas
        if (porcentajeParseado < 0) {
          auxErrProds.push({
            error: 'El porcentaje no puede ser negativo',
            campo: 'porcentaje',
            index
          })
        } else if (porcentajeParseado > 100) {
          auxErrProds.push({
            error: 'El porcentaje no puede ser mayo que 100',
            campo: 'porcentaje',
            index
          })
        } else {
          porcentajeAcum = porcentajeAcum + porcentajeParseado
        }
      }
    })
    if (porcentajeAcum < 100) {
      auxErrProds.push({
        error: 'El porcentaje no suma 100',
        campo: 'porcentaje',
      })
    } else if (porcentajeAcum > 100) {
      auxErrProds.push({
        error: 'El porcentaje supera 100',
        campo: 'porcentaje',
      })
    }
    return auxErrProds
  }

  return { validarTablaProductos }
}
