import React,{useState} from 'react'
import UserName from '../components/Auth/UserName';
import Coordenadas from '../components/Auth/Coordenadas';
export default function Login() {
    const [coordenadas, setCoordenadas] = useState([])
    const [tarjeta, setTarjeta] = useState(null);
  return (
    <>
        {
        coordenadas.length === 2 ?
            <Coordenadas coordenadas={coordenadas} tarjeta={tarjeta}/>
        :
            <UserName setCoordenadas={setCoordenadas} setTarjeta={setTarjeta}/>
        }
    </>
  )
}
