import * as Yup from 'yup';

export const coordenadasSchema = Yup.object().shape({
    coordenada1: Yup.number("La coordenada debe ser un numero")
      .required("Este campo es obligatorio")
      .positive("La coordenada debe ser un valor positivo")
      .integer("La coordenada debe ser un numero entero"),
    coordenada2: Yup.number("La coordenada debe ser un numero")
      .required("Este campo es obligatorio")  
      .positive("La coordenada debe ser un valor positivo")
      .integer("La coordenada debe ser un numero entero"),
  });
  