import React from 'react'
import Card from 'react-bootstrap/Card';
export default function TicketCamion({ ticket }) {
    return (
        <>
            <Card
                bg='light'
                text='black'
            >
                <Card.Header>
                    <h4><b>Ticket: </b>{ticket.ctrl}</h4>
                </Card.Header>
                <Card.Body>
                    <Card.Text>
                        <ul>
                            <li><b>Cliente: </b>{ticket.nomempre}</li>
                            <li><b>Chofer: </b>{ticket.nomchofer}</li>
                            <li><b>Camión: </b>{ticket.camion}</li>
                            <li><b>Chasis: </b>{ticket.patcha}</li>
                            <li><b>Acoplado: </b>{ticket.pataco}</li>
                            <li><b>Tara: </b>{ticket.tara}</li>
                            <li><b>Fecha tara: </b>
                                {String(ticket.fechatara).trimEnd().substring(0, 19).replace('T', ' ')}
                            </li>
                            <li><b>Balanza tara: </b>{ticket.baltara}</li>
                            <li><b>Bruto: </b>{ticket.bruto}</li>
                            <li><b>Fecha bruto: </b>
                                {String(ticket.fechabruto).trimEnd().substring(0, 19).replace('T', ' ')}
                            </li>
                            <li><b>Balanza bruto: </b>{ticket.balbruto}</li>
                            <li><b>Neto: </b>{ticket.neto}</li>
                            <li><b>Producto: </b>{ticket.mezcla}</li>
                        </ul>
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    )
}
